<template>
  <div class="project__container">
    <div
      class="project__menus-container"
      :class="{
        vHidden: subMenusOpen
      }"
    >
      <div class="menu__row" v-for="(row, index) in menus" :key="index">
        <div
          class="menu__item"
          v-for="(item, i) in row"
          :key="i"
          :class="{
            actived: i === 0 && index === 0
          }"
        >
          <div class="item__link">
            <p>{{ item.name.en }}</p>
            <p>{{ item.name.cn }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="project__lists-container">
      <div class="lists__inner">
        <index-shape :lists="lists" ref="indexComponent"></index-shape>
        <transition @enter="onListsEnter" @before-leave="onListsBeforeLeave" @leave="onListsLeave">
          <list-shape v-if="showLists" :lists="lists2"></list-shape>
        </transition>
      </div>
    </div>
    <div
      class="info__switch__bt"
      :class="{
        normal: !showLists,
        actived: showLists,
        vHidden: subMenusOpen
      }"
      ref="isb"
      @click="showLists = !showLists"
    >
      <svg class="bt__icon" xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 16 16">
        <rect fill="#000" width="2" height="2"></rect>
        <rect fill="#000" x="5" width="2" height="2"></rect>
        <rect fill="#000" x="10" width="2" height="2"></rect>
        <rect fill="#000" y="5" width="2" height="2"></rect>
        <rect fill="#000" x="5" y="5" width="2" height="2"></rect>
        <rect fill="#000" x="10" y="5" width="2" height="2"></rect>
        <rect fill="#000" y="10" width="2" height="2"></rect>
        <rect fill="#000" x="5" y="10" width="2" height="2"></rect>
        <rect fill="#000" x="10" y="10" width="2" height="2"></rect>
      </svg>
      <p class="bt__text">
        <span>all picture</span>
        <span>1111</span>
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import gsap from 'gsap';
import { mapState } from 'vuex';
import indexShape from './_index.vue';
import listShape from './_list.vue';

const tl = gsap.timeline();

export default {
  name: 'views-am2',
  components: {
    indexShape,
    listShape,
  },
  data() {
    return {
      menus: [
        [
          {
            name: {
              en: 'sales center',
              cn: '展示中心',
            },
          },
          {
            name: {
              en: 'residence',
              cn: '住宅空间',
            },
          },
          {
            name: {
              en: 'office',
              cn: '办公空间',
            },
          },
        ],
        [
          {
            name: {
              en: 'hotel&club',
              cn: '酒店会所',
            },
          },
          {
            name: {
              en: 'entire case custom',
              cn: '全案定制',
            },
          },
        ],
      ],
      lists: [
        {
          srcs: [require('@/assets/images/demo1/p1/1.png')],
          position: 'right',
          title: {
            en: 'Zibo Vanke Jade East Underground Club',
            cn: '淄博万科翡翠东第地下会所',
          },
        },
        {
          srcs: [
            require('@/assets/images/demo1/p1/2.png'),
            require('@/assets/images/demo1/p1/3.png'),
          ],
          position: 'between',
          title: {
            en: 'Zhengrong Hour Light Life Museum',
            cn: '正荣青云宸院展示中心',
          },
        },
        {
          srcs: [require('@/assets/images/demo1/p1/4.png')],
          position: 'right',
          title: {
            en: 'China Resources Eternal Bea',
            cn: '沈阳华润瑞府展示中心',
          },
        },
        {
          srcs: [
            require('@/assets/images/demo1/p1/7.png'),
            require('@/assets/images/demo1/p1/8.png'),
          ],
          position: 'left',
          title: {
            en: 'Ronshine Quiet Leisure Garden',
            cn: '融信·青澜园展示中心',
          },
        },
        {
          srcs: [require('@/assets/images/demo1/p1/9.png')],
          position: 'center',
          title: {
            en: 'Rongxin Country Garden',
            cn: '融信.碧桂园大境风华售楼处',
          },
        },
      ],
      lists2: [
        {
          srcs: [require('@/assets/images/demo1/p2/1.png')],
          title: {
            en: 'Zibo Vanke Jade East Underground Club',
            cn: '淄博万科翡翠东第地下会所',
          },
        },
        {
          srcs: [require('@/assets/images/demo1/p2/2.png')],
          title: {
            en: 'Zhengrong Hour Light Life Museum',
            cn: '正荣青云宸院展示中心',
          },
        },
        {
          srcs: [require('@/assets/images/demo1/p2/3.png')],
          title: {
            en: 'China Resources Eternal Bea',
            cn: '沈阳华润瑞府展示中心',
          },
        },
        {
          srcs: [require('@/assets/images/demo1/p2/4.png')],
          title: {
            en: 'Ronshine Quiet Leisure Garden',
            cn: '融信·青澜园展示中心',
          },
        },
        {
          srcs: [require('@/assets/images/demo1/p2/5.png')],
          title: {
            en: 'Rongxin Country Garden',
            cn: '融信.碧桂园大境风华售楼处',
          },
        },
        {
          srcs: [require('@/assets/images/demo1/p2/6.png')],
          title: {
            en: 'Evian Shangcheng Marketing Center',
            cn: '招商·金茂·正荣青山湖·依云上城营销中心',
          },
        },
        {
          srcs: [require('@/assets/images/demo1/p2/7.png')],
          title: {
            en: 'Sunac Shanghai Yinghong Bridge',
            cn: '融创.上海映红桥展示中心',
          },
        },
        {
          srcs: [require('@/assets/images/demo1/p2/8.png')],
          title: {
            en: 'Westlake Shangchen',
            cn: '苏州·湖西上辰展示中心',
          },
        },
        {
          srcs: [require('@/assets/images/demo1/p2/9.png')],
          title: {
            en: 'Rongxin Country Garden',
            cn: '新城玺樾华庭售楼处',
          },
        },
        {
          srcs: [require('@/assets/images/demo1/p2/10.png')],
          title: {
            en: 'Evian Shangcheng  Marketing Center',
            cn: '玖悦澜湾',
          },
        },
      ],
      showLists: false,
      currentShape: 'index',
    };
  },
  computed: {
    ...mapState(['subMenusOpen']),
  },
  mounted() {
    this.$on('firstImgOffsetLeft', (offset) => {
      this.$refs.isb.style.left = `${offset}px`;
    });
  },
  methods: {
    onListsEnter(el, done) {
      const that = this;
      done();
      setTimeout(() => {
        // el.classList.add('enter');
        tl.fromTo(
          that.$refs.indexComponent.$el,
          {
            y: 0,
            opacity: 1,
          },
          {
            y: '100vh',
            opacity: 0,
            display: 'none',
            duration: 0.5,
          },
        );
        tl.fromTo(
          el,
          {
            y: '100vh',
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            duration: 0.1,
            onComplete: () => {
              that.$nextTick(() => {
                document.documentElement.scrollTop = 0;
                that.pageInit();
              });
            },
          },
        );
      }, 10);
    },
    onListsBeforeLeave(el) {
      const that = this;
      tl.fromTo(
        el,
        {
          y: 0,
          opacity: 1,
        },
        {
          y: '100vh',
          opacity: 0,
          duration: 0.5,
          onComplete: () => {
            that.$nextTick(() => {
              document.documentElement.scrollTop = 0;
            });
          },
        },
      );
      tl.set(that.$refs.indexComponent.$el, {
        display: 'block',
      });
      tl.fromTo(
        that.$refs.indexComponent.$el,
        {
          y: '100vh',
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 1,
        },
      );
    },
    onListsLeave(el, done) {
      setTimeout(() => {
        done();
      }, 800);
    },
  },
};
</script>

<style></style>
